/* CSS reset ver 1.0 20121228 wanghao*/

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

fieldset,
img {
  border: 0;
}

:focus {
  outline: 0;
}

address,
caption,
cite,
code,
dfn,
em,
th,
var,
optgroup {
  font-style: normal;
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

abbr,
acronym {
  border: 0;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
}

input,
button,
textarea,
select,
optgroup,
option {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
}

code,
kbd,
samp,
tt {
  font-size: 100%;
}

input,
button,
textarea,
select {
  *font-size: 100%;
}

body {
  line-height: 1.5;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
}

sup,
sub {
  font-size: 100%;
  vertical-align: baseline;
}

:link,
:visited,
ins {
  text-decoration: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

/* CSS Document */

.clearfix {
  margin-right: auto;
  margin-left: auto;
  *zoom: 1;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}

.clearfix:after {
  clear: both;
}

b,
em,
i {
  font-style: normal;
  font-weight: normal;
}

body {
  margin: 0;
  font-family: "Microsoft Yahei",Arial,Tahoma,Helvetica,"SimSun","Hiragino Sans GB",sans-serif;
  background-color: #fff;
  position: relative;
}

html,
body {
  position: relative;
  overflow-x: hidden;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
}

img {
  vertical-align: top;
  border: none;
}

button,
input,
select,
textarea,
th {
  background: #fff;
  font-size: 0.100503rem;
  line-height: 1.5em;
  font-family: "Microsoft Yahei",Arial,Tahoma,Helvetica,"SimSun","Hiragino Sans GB",sans-serif;
}

input[type="submit"],
input[type="reset"],
input[type="password"],
input[type="button"],
input[type="text"],
button {
  -webkit-appearance: none;
}

/*取消苹果渲染*/

a {
  color: #4c4c4c;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-user-select: none;
  -moz-user-focus: none;
  -moz-user-select: none;
}

input {
  border: none;
  background: none;
}

;
a:hover,
a:focus {
  transition: color 150ms ease-in 0ms,background-color 150ms ease-in 150ms;
}

.rotate360:hover {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.rotate360 {
  transition: all 0.5s ease-in-out;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* *{touch-action: none;} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.am-pull-to-refresh-down .am-pull-to-refresh-indicator {
  font-size: 0.20938rem;
}

.am-list-body {
  background-color: transparent !important;
}

.am-list-body::before,
.am-list-body::after {
  height: 0 !important;
}

.color333 {
  color: #333333;
}

.color999 {
  color: #999999;
}

.fw700 {
  font-weight: 700;
}

.fontsize24 {
  font-size: 0.201005rem;
}

.fontsize26 {
  font-size: 0.217755rem;
}

.fontsize28 {
  font-size: 0.234506rem;
}

.fontsize30 {
  font-size: 0.251256rem;
}

.line-h1 {
  line-height: 1;
}

.line-h14 {
  line-height: 1.4;
}

.mg_t16 {
  margin-top: 0.134003rem;
}

.mg_lr40 {
  margin: 0 0.335008rem 0 0.335008rem;
}

.mg_t32 {
  margin-top: 0.268007rem;
}

.mg_t28 {
  margin-top: 0.234506rem;
}

.mg_lauto {
  margin-left: auto;
}

.pd_32 {
  padding: 0.268007rem !important;
}

.dis_line {
  display: inline;
}

.flex {
  display: flex;
}

.flex_dC {
  display: flex;
  flex-direction: column;
}

.flex_cc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex_jc {
  display: flex;
  justify-content: center;
}

.flex_js {
  display: flex;
  justify-content: space-between;
}

.flex_ac {
  display: flex;
  align-items: center;
}

.flex1 {
  flex: 1 1;
}

.y_card {
  background-color: #ffffff;
  border-radius: 0.268007rem;
  display: flex;
  padding: 0.201005rem;
}

.y_card2 {
  background-color: #ffffff;
  border-radius: 0.268007rem;
  padding: 0.268007rem;
  margin: 0.268007rem 0.335008rem 0;
}

.overflow_one {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.overflow_two {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.img_box1 {
  width: 1.574539rem;
  height: 1.574539rem;
  margin-right: 0.201005rem;
  flex-shrink: 0;
}

.am-toast.am-toast-mask {
  align-items: flex-start !important;
  padding-top: 1.507538rem;
}

.am-modal-button-group-v .am-modal-button {
  color: #FA8142 !important;
}

.am-modal-button-group-h .am-modal-button:last-child {
  color: #FA8142 !important;
}

.adm-picker-header-button:nth-child(1) {
  color: #898B8D;
}

.adm-picker-header-button:nth-child(3) {
  color: #CEAB87;
}

.adm-dialog-body-message {
  text-align: center;
  margin-top: 0.41876rem;
  margin-bottom: 0.41876rem;
}
